<template>
  <div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-center justify-content-end">
        <b-col md="12" class="px-3 pt-1">
          <h3 class="font-weight-bolder text-colorBlack">My Subjects</h3>
          <p class="font-weight-bold text-colorGray mb-50">
            Your subjects are display here
          </p>
        </b-col>
        <!-- <b-col md="4" class="pt-1 px-3">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                id="subjectCode"
                v-model="subjectCode"
                placeholder="Search By Subject Code"
                type="text"
              />
            </b-input-group>
          </b-form-group>
        </b-col> -->
      </b-row>
      <b-row class="d-flex  mt-2 px-2">
        <b-col md="3" class="bg-colorWhite" style="border-radius: 20px;" v-for="sub in subjects" :key="sub.id">
            <b-link :to="{ name: 'Lecture' }">
              <b-card
                img-src="@/assets/images/icons/subject/subject.png"
                img-alt="Image"
                img-top
              >
                <b-card-title class="text-colorBlack">{{sub.name}}</b-card-title>
                <b-card-text> {{ sub.code }} </b-card-text>
                <b-card-text> By {{ sub.teacher_data.name }} </b-card-text>
                <template #footer>
                  <b-row class="d-flex align-items-center justify-content-end">
                    <b-col md="9">
                      <h6 class="font-weight-bolder text-colorBlack">
                        {{lectures_completed}}/{{sub.lectures_data.length}} Lectures Completed
                      </h6>
                    </b-col>
                    <b-col md="3"
                      ><h4 class="font-weight-bolder text-colorBlack">{{lectures_completed*100/sub.lectures_data.length}}%</h4>
                    </b-col>
                  </b-row>
                  <b-progress
                    :value="lectures_completed"
                    :max="totalItems"
                    show-progress
                    animated
                  ></b-progress>
                </template>
              </b-card>
            </b-link>
            
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      subjectCode: "",
      value: 45,
      max: 100,
      subjects: [],
      totalItems: 0,
      lectures_completed: 0,
    };
  },
  methods: {
    ...mapActions({
      getSubjects: "appData/getSubjectDropDown",
      getLectures: "appData/getLectures",

    }),
  },
  async mounted() {
    const res = await this.getSubjects();
    this.subjects = res.data;
  },
};
</script>

<style></style>
